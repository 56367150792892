@import '@/assets/styles/global.scss';

.AuthTop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  $self: &;

  &_line {
    flex-direction: row;
    justify-content: flex-start;
    #{$self} {
      &-title {
        margin: 0;
      }

      &-logo {
        margin-right: 60px;
      }
    }
  }

  &-logo {
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    margin-top: 20px;
  }
}
