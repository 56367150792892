@import '@/assets/styles/global.scss';

.StepInfo {
  &-title {
    color: #000;
    font-size: 24px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  &-item {
    display: flex;
    padding-bottom: 23px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 15px;
      top: 32px;
      height: calc(100% - 14px - 32px);
      width: 1px;
      background-color: #f1f1f1;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &-number {
    color: var(--text-h-2, #a4acb7);
    font-size: 18px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 50%;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    &_active {
      color: #fff;
      background-color: var(--primary-color);
    }
  }

  &-name {
    color: #a4acb7;
    font-size: 18px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    &_active {
      color: #1e1f26;
    }
  }
  &-text {
    color: #a4acb7;
    font-size: 14px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
  }

  &-info {
    width: calc(100% - 44px);
  }
  &-list {
    margin-top: 25px;
  }
}
