@import '@/assets/styles/global.scss';

.Dropdown {
  position: relative;

  &-selected {
    padding: 0;
  }

  &-list {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    right: 0;
    border: 1px solid var(--gray-color-100);
    z-index: 50;
    background-color: var(--white-color);
  }

  &-item {
    width: 100%;
    padding: 5px 10px;
    text-align: left;
    border-bottom: 1px solid var(--gray-color-100);
    min-height: 36px;

    color: var(--black-color-200);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.126px;

    &:last-child {
      border: none;
    }
  }
}
