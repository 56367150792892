@import '@/assets/styles/global.scss';

.CountrySelect {
  position: relative;
  width: 100%;

  &-selected {
    min-width: 75px;
    background-color: var(--primary-color);
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--white-color);
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
  }

  &-list {
    height: 216px;
    padding: 8px;
    background: var(--white-color);
    border: 1px solid var(--gray-color-100);
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    z-index: 50;
  }

  &-scroll {
    height: 100%;
  }

  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;

    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--body-color);

    &_selected {
      background-color: var(--button-gray-bg);
    }

    &:hover {
      background-color: var(--gray-color-200);
      color: var(--button-primary-bg);
    }
  }

  &-code {
    text-align: left;
    font-weight: 600;
    min-width: 48px;
    margin-right: 8px;
  }
}
