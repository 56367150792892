@import '@/assets/styles/global.scss';

.ExchangePage {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  &-wrap {
    max-width: 600px;
    border: none;

    @include tablet {
      max-width: 100%;
    }
  }
}
