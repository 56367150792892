.Pagination {
  display: flex;

  &_center {
    justify-content: center;
  }

  &_right {
    justify-content: flex-end;
  }

  &_left {
    justify-content: flex-start;
  }

  &-list {
    align-items: center;
    display: flex;
  }

  &-item {
  }

  &-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;

    border-radius: 8px;

    &_active {
      background-color: var(--gray-color-100);
      color: var(--body-color);
    }
  }

  &-first,
  &-last {
    svg {
      path {
        fill: var(--gray-color);
      }
    }
  }
}
