@import '@/assets/styles/global.scss';

.TransactionDetail {
  &-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--dark-color);
    word-break: break-all;

    &_success {
      color: var(--green-color);
    }
    &_warning {
      color: var(--yellow-color);
    }
    &_danger {
      color: var(--red-color);
    }
  }

  &-tx {
    word-break: break-all;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-color-100);
  }

  &-link {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-color);

    svg {
      margin-left: 5px;
      path {
        stroke: var(--primary-color);
      }
    }
  }

  &-bottom {
    margin-top: 18px;
  }

  &-btn {
    width: 100%;
    max-width: 261px;
  }
}
