@import '@/assets/styles/global.scss';

.Item {
  width: 50%;
  padding: 18px 25px;

  &:nth-child(odd) {
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
  }

  &:nth-child(even) {
    border-bottom: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
  }

  &:first-child,
  &:nth-child(2) {
    border-top: 1px solid #f1f1f1;
  }

  &_small {
    padding: 12px 15px;
  }

  &_full {
    width: 100%;
    border-right: 1px solid #f1f1f1;
  }

  &_mobile {
    width: 100%;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      border-bottom: none;
    }
    //   border-top: none;
    //   padding: 10px;

    //   &:nth-child(odd) {
    //     border-bottom: initial;
    //     border-left: initial;
    //   }

    //   &:nth-child(even) {
    //     border-bottom: initial;
    //     border-right: initial;
    //   }

    //   &:first-child {
    //     border-top: 1px solid #f1f1f1;
    //   }
  }

  &-label {
    color: #a4acb7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;

    &_small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &-value {
    color: #1e1f26;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    word-break: break-all;
  }
}
