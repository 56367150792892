@import '@/assets/styles/global.scss';

.Grid {
  width: 100%;
  display: table;
  $self: &;

  &-row {
    display: table-row;

    #{$self} {
      &-head,
      &-cell {
        &:first-child {
          padding-left: 28px;
          border-left: 1px solid var(--gray-color-100);
        }

        &:last-child {
          padding-right: 28px;
          border-right: 1px solid var(--gray-color-100);
        }
      }
    }

    &_pointer {
      cursor: pointer;
    }
  }

  &-head,
  &-cell {
    vertical-align: middle;
    display: table-cell;
    padding: 16px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--body-color);
    border-bottom: 1px solid var(--gray-color-100);
  }

  &-head {
    color: var(--gray-color);
    border-top: 1px solid var(--gray-color-100);
  }

  &-footer {
    width: 100%;
    padding: 16px 28px;
    border: 1px solid var(--gray-color-100);
    border-top: none;
  }
}
