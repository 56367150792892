@import '@/assets/styles/global.scss';

.Badge {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 200px;
  padding: 6px 12px 6px 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: var(--gray-color-200);

  &_small {
    padding: 3px 5px;
    font-size: 12px;
    line-height: 1;
  }

  &-icon {
    margin-right: 7px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
