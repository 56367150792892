@import '@/assets/styles/global.scss';

.ComponentToPrint {
  padding: 30px;

  &-wrap {
    margin-top: 30px;
    border: 1px solid var(--gray-color-100);
    display: flex;
    flex-wrap: wrap;
  }

  &-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    border-bottom: 1px solid var(--gray-color-100);
    @include xmobile {
      width: 100%;
    }
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
  }

  &-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--dark-color);
    word-break: break-all;

    &_success {
      color: var(--green-color);
    }
    &_warning {
      color: var(--yellow-color);
    }
    &_danger {
      color: var(--red-color);
    }
  }
}
