@import '@/assets/styles/global.scss';

.AppProfile {
  width: 100%;
  display: flex;

  @include desktop {
    flex-direction: column;
  }

  &-content {
    max-width: calc(100% - 420px);
    width: 100%;
    border-right: 1px solid var(--gray-color-100);
    border-left: 1px solid var(--gray-color-100);
    padding: 40px 30px;

    @include rwd(1400) {
      padding: 30px 15px;
      max-width: calc(100% - 390px);
    }

    @include desktop {
      // max-width: calc(100% - 350px);
      max-width: 100%;
    }

    // @include widescreen {
    //   max-width: 100%;
    // }
    @include tablet {
      border: none;
    }
    @include mobile {
      padding: 15px;
    }
  }
  &-sidebar {
    max-width: 420px;
    width: 100%;

    @include rwd(1400) {
      max-width: 390px;
    }

    @include desktop {
      // max-width: 350px;
      max-width: 100%;
    }

    // @include widescreen {
    //   max-width: 100%;
    // }

    &_padding {
      padding: 40px 30px;
    }
  }
}
