@import '@/assets/styles/global.scss';

.Step {
  &-text {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  &-list {
    display: flex;
    align-items: center;
  }
  &-line {
    height: 2px;
    width: 100%;
    margin-right: 4px;
    background-color: var(--gray-color-100);
    &:last-child {
      margin: 0;
    }

    &_active {
      background-color: var(--primary-color);
    }
  }

  &-prev {
    padding: 0;
    margin-right: 10px;
    svg {
      path {
        @extend %transition;
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
}
