@import '@/assets/styles/global.scss';

.Title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--bg-header-color);

  &_small {
    font-size: 20px;
    line-height: 20px;
  }
}
