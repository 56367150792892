@import '@/assets/styles/global.scss';

.Private {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-content {
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin: 0 auto;
    display: flex;

    @include rwd(1400) {
      padding: 0 15px;
    }
    @include tablet {
      padding: 0;
    }
  }

  &-sidebar {
    max-width: 190px;
    width: 100%;
    padding: 40px 30px 40px 0;

    @include rwd(1400) {
      padding: 30px 15px 30px 0;
    }

    @include desktop {
      max-width: 160px;
    }

    @include tablet {
      display: none;
    }
  }

  &-menu {
    margin-top: 50px;
  }

  &-button {
    color: var(--body-color);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: 1px solid var(--gray-color-100);
    width: 100%;
    margin-top: 16px;
    svg {
      margin-right: 8px;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-info {
    max-width: calc(100% - 45px);
    padding-left: 10px;
  }

  &-user {
    text-align: left;
    padding: 0;
  }

  &-fullname {
    color: var(--black-color);
    font-size: 14px;
    font-family: Ubuntu;
    line-height: 18px;
  }

  &-mobile {
    background-color: var(--white-color);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
  }

  &-type {
    margin-top: 3px;
    font-weight: 400;
    color: var(--gray-color-600);
    text-decoration: underline;
    line-height: 18px;
    font-size: 12px;
    padding: 0;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
