@import '@/assets/styles/global.scss';

.UserVerification {
  &-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @include xmobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-card {
    padding: 20px;
    position: relative;

    &:last-child {
      grid-column: 1/-1;
    }

    &_top {
      margin-top: 30px;
    }
  }

  &-icon {
    position: absolute;
    right: 7px;
    top: 7px;
  }

  &-label {
    color: #a4acb7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
    display: block;
  }

  &-input {
    color: #1e1f26;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    &::placeholder {
      color: #a4acb7;
    }
  }

  &-action {
    margin-top: 30px;
    text-align: center;
  }

  &-field {
    &_center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-commit {
    margin-top: 10px;
  }
}
