.Loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--loading-circle-one);
    margin-right: 20px;

    &:first-child {
      animation: change-background 1s linear infinite;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      background-color: var(--loading-circle-two);
      animation: change-background 1s linear infinite;
      animation-delay: 0.3s;
    }

    &:last-child {
      margin: 0;
      background-color: var(--loading-circle-three);
      animation: change-background 1s linear infinite;
      animation-delay: 0.6s;
    }
  }

  &-small {
    line-height: 1;
    svg {
      transition-duration: 0.5s;
      transition-property: opacity;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: rotate;
      animation-timing-function: linear;
    }

    &_center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_left {
      justify-content: flex-start;
    }
    &_right {
      justify-content: flex-end;
    }
  }

  &-lines {
    &_center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_left {
      justify-content: flex-start;
    }
    &_right {
      justify-content: flex-end;
    }
  }
}

@keyframes change-background {
  0% {
    background-color: var(--loading-circle-one);
  }
  50% {
    background-color: var(--loading-circle-two);
  }
  100% {
    background-color: var(--loading-circle-three);
  }
}

// @keyframes change-background {
//   0% {
//     background-color: var(--loading-circle-one);
//   }
//   25% {
//     background-color: var(--loading-circle-two);
//   }
//   50% {
//     background-color: var(--loading-circle-three);
//   }
//   100% {
//     background-color: var(--loading-circle-one);
//   }
// }

// @keyframes change-background-two {
//   0% {
//     background-color: var(--loading-circle-two);
//   }
//   25% {
//     background-color: var(--loading-circle-three);
//   }
//   50% {
//     background-color: var(--loading-circle-one);
//   }
//   100% {
//     background-color: var(--loading-circle-two);
//   }
// }

// @keyframes change-background-three {
//   0% {
//     background-color: var(--loading-circle-three);
//   }
//   25% {
//     background-color: var(--loading-circle-two);
//   }
//   50% {
//     background-color: var(--loading-circle-one);
//   }
//   100% {
//     background-color: var(--loading-circle-three);
//   }
// }

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
