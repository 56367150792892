.Upload {
  position: relative;
  display: inline-flex;
  width: auto;

  &-input {
    position: absolute;
    z-index: -99999;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
