@import '@/assets/styles/global.scss';

.PaymentAddress {
  &-copy {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    span {
      display: flex;
      margin-left: 17px;
      cursor: pointer;
    }
  }
}
