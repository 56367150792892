@import '@/assets/styles/global.scss';

.Transactions {
  &-table {
    margin-top: 30px;
    overflow-x: auto;
  }

  &-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-value {
    width: 60%;
    &:last-child {
      width: 40%;
    }
  }
}
