@import '@/assets/styles/global.scss';

.Button {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 16px;
  // font-size: 18px;
  line-height: 20px;

  $self: &;
  @extend %transition;

  svg {
    path {
      @extend %transition;
    }
  }

  // &:hover {
  //   // background-color: $dark-orange;
  //   // border: 1px solid $dark-orange;
  // }

  // &:active {
  //   background-color: $dark-orange;
  //   box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  // }

  // &[disabled] {
  //   pointer-events: none;
  //   background-color: $secondary;
  //   border: 1px solid $secondary;
  // }

  &_sm {
    padding: 6px 12px;
    min-height: 32px;
    font-size: 14px;
    line-height: 20px;
  }

  &_md {
    min-height: 42px;
    padding: 6px 14px;
    min-height: 42px;
  }

  &_lg {
    padding: 10px 15px;
    min-height: 46px;
  }

  &_xl {
    padding: 5px 15px;
    min-height: 48px;
  }

  &_xxl {
    padding: 5px 15px;
    min-height: 56px;
  }

  &_blue {
    color: var(--white-color);
    background-color: var(--button-primary-bg);

    &:hover {
      background-color: var(--button-primary-bg);
    }
  }

  &_outline {
    color: var(--body-color);
    background-color: var(--white-color);
    border: 1px solid var(--gray-color-100);
    &:hover {
      border: 1px solid var(--primary-color);
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }

  &_gray {
    background: var(--gray-color-200);
    color: var(--body-color);
  }

  &_fluid {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -11px;
    width: 20px;
    height: 20px;
    border: 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
  }

  &_loading {
    &::before {
      opacity: 1;
    }
  }

  &-icon {
    line-height: 1;
    &_left {
      margin-right: 12px;
    }
    &_right {
      margin-left: 12px;
    }
  }
}

// Animations
//**********************//

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
