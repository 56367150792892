.Avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &_small {
    width: 45px;
    height: 45px;
  }

  &_empty {
    background-color: var(--gray-color-100);
  }
}
