@import '@/assets/styles/global.scss';

.FieldPhone {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-label {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-color);
    display: block;
  }

  &-input {
    width: 100%;
    background-color: var(--white-color);
    border: 1px solid var(--gray-color-100);
    font-weight: 400;
    // font-size: 18px;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-900);
    padding: 10px 16px 10px 90px;

    @extend %transition;

    &::placeholder {
      color: var(--gray-color-400);
    }

    &:focus:not(.Field-input_errors) {
      border: 1px solid var(--input-border-focus);
    }

    &_left {
      padding-left: 43px;
    }
    &_right {
      padding-right: 43px;
    }

    &_xxl {
      min-height: 42px;
    }

    &_xl {
      min-height: 40px;
    }

    &_lg {
      min-height: 36px;
    }

    &_md {
      min-height: 30px;
    }

    &_sm {
      min-height: 28px;
    }

    &_errors {
      border: 1px solid var(--red-color);
    }

    @include widescreen {
      font-size: 16px;
    }
  }

  &-error {
    margin-top: 5px;
    transition: all 0.5s ease;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: var(--black-color);
    display: flex;
    align-items: center;
    position: absolute;
    color: var(--red-color);

    // svg {
    //   margin-right: 10px;
    // }

    &.entering,
    &.entered {
      animation: slideError 0.5s ease-out forwards;
    }

    &.exiting {
      animation: slideError 0.5s ease-in alternate-reverse;
    }

    &.exited {
      opacity: 0;
    }
  }

  &-select {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 51;
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
