@import '@/assets/styles/global.scss';

.Home {
  &-list {
    margin-top: 30px;
    @include mobile {
      margin-top: 15px;
    }
  }

  &-loading {
    min-height: 180px;
  }
}
