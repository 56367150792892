@import '@/assets/styles/global.scss';

.Payment {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-wrap {
    margin-top: 30px;
    border: 1px solid var(--gray-color-100);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    border-bottom: 1px solid var(--gray-color-100);
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
  }

  &-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--dark-color);
    word-break: break-all;
  }

  &-card {
    height: 200px;
  }

  &-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
    @include widescreen {
      width: 100%;
    }
  }

  &-iframe {
    min-height: 600px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
