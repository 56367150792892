@import '@/assets/styles/global.scss';

.EditField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &-wrap {
    &_edit {
      width: 100%;
    }
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
    margin-bottom: 4px;
  }

  &-value {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--body-color);
  }

  &-btn {
    min-height: 38px;
    font-size: 14px;
    line-height: 1;
    &_open {
      position: absolute;
      right: 4px;
      bottom: 4px;
    }
  }
}
