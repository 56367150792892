@import '@/assets/styles/global.scss';

.Settings {
  &-upload {
    display: flex;
    align-items: center;
  }

  &-avatar {
    margin-right: 12px;
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--body-color);
  }
  &-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-card {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    &:first-child {
      position: relative;
      z-index: 1;
    }
  }

  &-left {
    width: calc(55% - 10px);
    @include mobile {
      width: 100%;
      margin-bottom: 25px;
    }
  }
  &-right {
    width: calc(45% - 10px);
    @include mobile {
      width: 100%;
    }
  }

  &-el {
    padding: 20px 15px;
    border-bottom: 1px solid var(--gray-color-100);

    &:last-child {
      border: none;
    }

    &_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
    margin-bottom: 4px;

    &_bn {
      margin-bottom: 0;
    }
  }

  &-value {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--body-color);
  }

  &-qr {
    margin-top: 20px;
  }

  &-save {
    // position: absolute;
    // right: 3px;
    // top: 3px;
  }

  &-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &-switch {
    margin-left: 10px;
  }

  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
