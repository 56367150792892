.FieldCode {
  &-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-color);
    margin-bottom: 4px;
    display: block;
  }

  &-input {
    background: var(--white-color);
    border: 1px solid var(--gray-color-100);

    width: 100%;
    min-height: 48px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: var(--dark-color);
    padding: 0;
    vertical-align: middle;

    &:focus {
      border: 2px solid var(--primary-color);
    }

    &::placeholder {
      color: vvar(--gray-color-400);
      font-weight: 400;
    }

    &_success {
      border: 2px solid var(--green-color-100);
    }

    // &[disabled] {
    //   background: var(--text-gray-small);
    //   border: 1px solid var(--text-gray-small);
    //   color: var(--gray-200);
    // }

    // &_error {
    //   color: var(--red);
    //   border: 1px solid var(--red-medium);
    // }
  }

  &-bottom {
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-resend {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-700);
    padding: 0;
  }

  &-loading {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-700);
  }

  &-icon {
    margin-right: 8px;
  }

  &-success {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--green-color-200);
    svg {
      margin-right: 10px;
    }
  }

  &-error {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--red-color);
    svg {
      margin-right: 10px;
    }
  }
}
