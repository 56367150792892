@import '@/assets/styles/global.scss';

.AuthWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @include mobile {
    align-items: flex-start;
  }
}
