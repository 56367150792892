@import '@/assets/styles/global.scss';

.Exchange {
  border-right: 1px solid var(--gray-color-100);
  padding-bottom: 30px;

  &-top {
    padding: 35px 15px 25px;
  }

  &-tabs {
    display: flex;
  }

  &-tab {
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-color);
    padding: 17px 10px;
    border: 1px solid var(--gray-color-100);
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %transition;

    &_active {
      border-top: 1px solid var(--primary-color);
      border-bottom: 1px solid transparent;
    }

    &:last-child {
      color: var(--black-color-200);
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.126px;
      border-right: none;
      svg {
        margin-left: 15px;
      }
    }
  }

  &-form {
    position: relative;
  }

  &-el {
    padding: 30px 15px 40px;
    border-top: 1px solid var(--gray-color-100);
    &:first-child {
      border: none;
    }
  }

  &-course {
    padding: 8px 12px;
    height: 32px;
    background: var(--white-color);
    border: 1px solid var(--gray-color-200);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--gray-color);
    display: inline-flex;
    width: auto;
    position: absolute;
    left: 15px;
    top: 125px;
  }

  &-action {
    padding: 0 15px;
  }

  &-icon {
    margin-left: 12px;
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--body-color);
    text-transform: uppercase;
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-select {
    min-width: 100px;
  }

  &-value {
    margin-top: 4px;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: var(--body-color);
    display: flex;
    align-items: flex-end;
    small {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: var(--gray-color);
    }
  }

  &-field {
    margin-top: 4px;
    position: relative;
  }

  &-input {
    vertical-align: bottom;
    height: 30px;
    padding: 0;
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: var(--body-color);
    font-family: 'Ubuntu';

    &_wallet {
      font-size: 16px;
      line-height: 16;
      font-weight: 400;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--black-color);
    margin: 40px 0 20px;
    padding: 0 15px;
  }

  &-exchange {
    display: flex;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  &-arrow {
    margin: 0 10px;
  }

  &-currency {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-900);
    display: flex;
    align-items: center;
    padding: 11px 21px;
    border: 1px solid var(--gray-color-100);
    margin-right: 20px;
  }

  &-commision {
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
  }

  &-error {
    color: var(--red-color);
    position: absolute;
    left: 0;
    font-size: 12px;
  }

  &-available {
    margin-top: 10px;
    color: #a4acb7;
    font-size: 12px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &-wrap {
    position: relative;
  }
}
