@import '@/assets/styles/global.scss';

.Back {
  padding: 12px 16px;
  background-color: var(--gray-color-200);
  display: flex;
  align-items: center;

  &-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--gray-color);
    margin-left: 10px;
  }
  svg {
    path {
      fill: var(--primary-color);
    }
  }

  &_mr {
    margin-right: 15px;
  }
}
